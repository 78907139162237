.h-wrapper {
  background: var(--black);
  color: white;
}
.h-container {
  justify-content: space-between;
  padding-bottom: 1rem;
  padding-top: 1rem;
  color: var(--secondary);
}
.h-menu {
  gap: 2rem;
}
.h-menu > *:hover {
  cursor: pointer;
}
