.f-container {
  justify-content: space-between;
}
.f-left {
  gap: 1rem;
}
.f-menu {
  gap: 1.5rem;
  margin-top: 1.5rem;
}
.f-container .orangeText {
  font-size: 1.5rem;
}
